// --------------------------------------------------------- REACT -------
import * as React from 'react'
import { useState, useEffect, useCallback } from 'react'
import { memo } from 'react';

// import { useLinkClickHandler, useNavigate } from "react-router-dom"
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Stack,
    Button,
    IconButton,
    Collapse,
    Alert,
    Box,
    CircularProgress
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
import LoadingButton from '@mui/lab/LoadingButton'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
import {
    Close as CloseIcon,
} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
import { API_URL_SIMPLECRM } from '../../../components/common'
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
import {
    SimpleUIAuthState,
    SimpleUIUseCache,

    SimpleUIDialogListSelector,

    SimpleUICommonUseInterval
} from './../../../simpleUI'
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
import Element from './element'
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
const API_URL_SIMPLEDATA = "/api/simplecrm/entities/"
const API_URL_SIMPLEDATATYPE = "/api/simplecrm/entitytypes/"
// ----------------------------------------------------------------------

const Buttons = (props) => {
    const { formState, entity, handleOnClickMainButton, handleReturn } = props

    // console.log (formState)

    return (
        <React.Fragment>
            <LoadingButton color="inherit" loading={formState.mainButtonLoading} disabled={!formState.dirty || formState.locked} onClick={handleOnClickMainButton}>
                {(entity === undefined)
                    ? <React.Fragment>{(!entity?.id) ? "Create" : "Save" }</React.Fragment>
                    : <React.Fragment>{(!entity?.id) ? "Add" : "Apply" }</React.Fragment>
                }                
            </LoadingButton>          
            <Button color="inherit" onClick={handleReturn} disabled={formState.disabled}>
                Close
            </Button>
        </React.Fragment>
    )
}

export default memo((props) => {    
    const {typeId, entityId, createCallback, updateCallback, returnCallback} = props

    const {user, accessToken} = SimpleUIAuthState()
    const {cache, setCacheData} = SimpleUIUseCache()

    const formStateDefault = {
        isInitializing: true,

        mode: "",
 
        mainButtonDisabled: true,
        mainButtonLoading: false,

        disabled: false,
        locked: false,

        valid: false,

        warning: false,
        warningText: "",

        error: false,
        errorText: "",

        fieldError: {}
    }

    const [componentState, setComponentState] = useState({
        mode: null
    })

    const [renders, setRenders] = useState([])

    const [formState, setFormState] = useState(formStateDefault)

    const [selectorOpenAction, setSelectorOpenAction] = React.useState(false)

    const [type, setType] = useState(undefined)
    const [layout, setLayout] = useState(undefined)

    const [entityDefaultData, setDefaultEntityData] = useState(undefined)
    
    const [entity, setEntity] = useState(undefined)


    const [entityData, setEntityData] = useState(undefined) 

    useEffect(() => {
        // #region CREATE WITH PASSED TYPE
        if (
            (typeId) &&
            (entityId === undefined ) &&                
            (
                (entity === undefined) ||
                (entity === null)
            )
        ) {
            console.log ("CREATE WITH PASSED TYPE")
            setComponentState((prevState) => ({...prevState, mode: "create"}))
            setEntity({id: null, typeId: typeId, data:{}})
            return
        } 
        // #endregion

        // #region EDIT WITH PASSED ENTITYID
        if (
            (entityId) &&
            (props.entity === undefined)
        ) {          
            console.log ("EDIT WITH PASSED ENTITYID")
            setComponentState((prevState) => ({...prevState, mode: "edit"}))
            getEntity(entityId)
                .then (result => {
                    setEntity(result)
                }).catch (error => {
                    handleError(error)
                })            
            return
        }
        // #endregion

        // #region EDIT WITH PASSED ENTITY
        else if (
            (props.entity) &&
            (entityId === undefined)                        
        ) {
            console.log ("EDIT WITH PASSED ENTITY")
            setComponentState((prevState) => ({...prevState, mode: "edit"}))
            setEntity(props.entity)
        }
        // #endregion
    }, [])

    useEffect(() => {
        if (type) {             
            if (type.layoutsD?.edit?.content) {
                setLayout(structuredClone(type.layoutsD.edit.content))
            } else {
                let layout = {
                    version: 1,
                    build: [
                        {
                            type: "grid",
                            props: {
                                container: true
                            },                            
                            content: [
                                {
                                    type: "grid",
                                    props: {
                                        item: true,
                                        xs: 12
                                    },
                                    content: []
                                }                                
                            ]
                        }
                    ]
                }
            
                Object.keys(type.fields).forEach(fieldId => {
                    layout.build[0].content[0].content.push({
                        type: "field",
                        value: fieldId
                    })        
                })

                setLayout(structuredClone(layout))
            }
        }        
    }, [type])

    SimpleUICommonUseInterval(async () => {
        if (props.onChange)
            return
    //     // console.log ("Mode:"+ formState.mode)
        if (componentState.mode == "edit") {
            if (await handleLock()) {
                if (formState.locked)
                    setFormState({ ...formState, isInitializing: false, locked: false })
            } else {            
                if (!formState.locked)
                    setFormState({ ...formState, isInitializing: false, locked: true })                
            }
        }
    }, 10000)

    useEffect(() => {
        if (formState.locked)
            handleWarning ("This "+ type.name +" has been locked by another user. Lock will be acquired when released.")
        else 
            handleWarning ()
    }, [formState.locked])
    
    useEffect(() => {
        if (!entity)
            return

        if (props?.type?.id !== entity.typeId) {        
            getType(entity.typeId)
                .then(result => {
                    setType(result)
                })
                .catch (error => {
                    handleError(error)
                })
        } else {
            setType(props.type)
        }
    
        setDefaultEntityData(structuredClone(entity.data))
    }, [entity])

    useEffect(() => {
        if (!entityDefaultData)
            return

        setEntityData(structuredClone(entityDefaultData))
    }, [entityDefaultData])

    useEffect(() => {
        if (!entityData || !type)
            return

        let formValid = 0
        let formDirty = 0

        // Validate: CHANGED
        if (JSON.stringify(entityDefaultData) !== JSON.stringify(entityData)) {
            formDirty++
        }
        
        // Validate: REQUIRED FIELDS        
        // if (type.schema) {
            for (var fieldId in type.fields) {
                const field = type.fields[fieldId]                
                
                // console.log (field)

                if (field.required) {                    
                    if (!entityData.hasOwnProperty(fieldId)) {
                        formValid++
                        continue
                    }
                        
                    switch (field.type) {
                        case "select": {
                            if (Array.isArray(entityData[fieldId]) && entityData[fieldId].length == 0) {
                                formValid++
                            } else if (entityData[fieldId] instanceof Object) {
                                formValid++
                                for (var option in entityData[fieldId]) {
                                    if (entityData[fieldId][option]) {
                                        formValid--
                                        break
                                    }
                                }
                            } else if (entityData[fieldId] == "") {
                                formValid++
                            }                           
                            break
                        }

                        case "upload": {
                            break
                        }

                        default: {
                            if (entityData[fieldId] == "")
                                formValid++
                        }
                    }
                }
            }
        // }
    
        
        if (renders.length > 0) {
            // console.log (entity.id +" "+ renders.length)
            if (renders.find(render => !render.valid)) {
                // console.log (renders)
                formValid++
            }
                

            if (renders.find(render => render.dirty)) {                
                formDirty++             
            }                
        }
        
        // console.log (entity.id +" "+ formValid +" "+ !!(formValid))

        setFormState({...formState, 
            isInitializing: false,
            valid: !!(formValid),
            dirty: !!(formDirty),
            error: false,    
            errorText: "",           
            disabled: false, 
            mainButtonLoading: false,
            mainButtonDisabled: !!(formValid)
        })        
    }, [entityData, renders])

    useEffect(() => {
        if (props.onChange) {
            props.onChange(formState, type?.id, entityData, handleOnClickMainButton)
        }

    }, [formState, renders, entity, entityData])

    const handleLock = useCallback(async (entityId) => {
        if (!entityId)
            entityId = props.entityId
        
        let getLock = await fetch(`${API_URL_SIMPLECRM.ENTITIES}${entityId}`, {
            method: 'LOCK',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': "Bearer "+ accessToken
            }            
        })  
            
        if (getLock.ok)
            return true

        return false
    }, [])

    const handleUnlock = useCallback(async () => {
        let getLock = await fetch(`${API_URL_SIMPLECRM.ENTITIES}${entity.id}`, {
            method: 'UNLOCK',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': "Bearer "+ accessToken
            }            
        })                
    }, [entity])

    const handleCreate = useCallback(async () => {
        setFormState({ ...formState, disabled: true, mainButtonLoading: true })

        // if (props.entity !== undefined) {            
        //     const createdEntity = structuredClone(entity)            
        //     createdEntity.typeId = type.id
        //     createdEntity.data = structuredClone(entityData)
        //     setEntity(structuredClone(createdEntity))

        //     if (props.createCallback)
        //         props.createCallback(structuredClone(type), structuredClone(createdEntity))

        //     handleReturn()

        //     return structuredClone(createdEntity)
        // } else {
            try {            
                
                let fetchPost = await fetch(`${API_URL_SIMPLECRM.ENTITIES}`, {
                    method: 'POST', 
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer "+ accessToken
                    },
                    body: JSON.stringify({                        
                        typeId: type.id,
                        data: entityData,
                    })
                })

                if (!fetchPost.ok)
                    throw new Error(await fetchPost.text())
                
                let createdEntity = await fetchPost.json()
                setEntity(createdEntity)

                await handleLock(createdEntity.id)

                if (createCallback)
                    createCallback(structuredClone(type), structuredClone(createdEntity))

                return structuredClone(createdEntity)
            } catch (error) {    
                console.log (error)                  
                handleError(error)
            }  
        // }      

        return null
    }, [entityData])

    const handleUpdate = async () => {
        setFormState({ ...formState, disabled: true, mainButtonLoading: true })

        if (props.entity !== undefined) {
            const updatedEntity = structuredClone(entity)
            updatedEntity.data = structuredClone(entityData)
            setEntity(structuredClone(updatedEntity))

            if (props.updateCallback)
                props.updateCallback(structuredClone(updatedEntity))

            handleReturn()

            return structuredClone(updatedEntity)
        } else {
            try {
                let fetchPatch = await fetch(`${API_URL_SIMPLECRM.ENTITIES}${entity.id}`, {
                    method: 'PATCH', 
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer "+ accessToken
                    },
                    body: JSON.stringify(
                        {data: entityData}
                    )
                })  

                if (!fetchPatch.ok)
                    throw new Error(await fetchPatch.text())

                let updatedEntity = structuredClone(entity)
                updatedEntity.data = structuredClone(entityData)                            
                setEntity(structuredClone(updatedEntity))

                if (props.updateCallback)
                    props.updateCallback(structuredClone(entity), structuredClone(type))

                return structuredClone(updatedEntity)
            } catch (error) {
                handleError(error)
            }
        }

        return null
    }

    const handleReturn = useCallback(async () => {
        if (props.entity === undefined)
            await handleUnlock()

        if (props.onChange)
            return

        if (props.returnCallback)
            props.returnCallback()
    }, [entity])

    const handleOnChange = (event) => {
        const id = (event.target.name || event.target.id)
        const value = event.target.value

        if ((formState.fieldError[id] || {}).error) {
            setFormState({...formState, 
                fieldError: {}
            })
        }
        
        setEntityData(prevState => {
            let newState = { ...prevState, [id]: value }
            return newState
        })

        return true
    }

    const handleWarning = (message) => {
        if (message)
            setFormState({ ...formState, warning: true, warningText: message })
        else
            setFormState({ ...formState, warning: false, warningText: message })
    }

    const handleError = (error) => {
        console.log (error)
        error = (JSON.parse(error.message)).error
        
        switch (error.code) {
            case "ER_ENTITY_IS_LOCKED_BY_OTHER_USER":
                setFormState({ ...formState, error: true, errorText: "This entity is edited by another user." })
                break
            case "ER_ENTITYTYPE_NOT_FOUND":
                setFormState({ ...formState, error: true, errorText: "EntityType not found." })
                break

            case "ER_ENTITY_NOT_FOUND":
                setFormState({ ...formState, error: true, errorText: "Entity not found." })
                break

            case "ER_ENTITY_DATA_ERROR": {                
                let messsage = JSON.parse(error.message)
                let fieldError = {}                
                fieldError[messsage.key] = {error: true, code: messsage.error.code, text: messsage.error.code}                

                setFormState({ ...formState, fieldError: fieldError})
                break
            }
            
            default:
                setFormState({ ...formState, error: true, errorText: "Unexpected error occured." })
        }
        // console.log (error.message)        
    }

    const handleFetchTypes = async () => {


        let getTypes = await fetch(API_URL_SIMPLEDATATYPE, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': "Bearer "+ accessToken
            }            
        })     

        if (!getTypes.ok)
            throw new Error((await getTypes.json()).error.code)
        
        return await getTypes.json()
    }

    const handleSelectType = async (data) => {
        try {
            let getType = await fetch(API_URL_SIMPLEDATATYPE + data[0], {
                method: 'GET',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer "+ accessToken
                }            
            })           

            if (!getType.ok)
               throw new Error((await getType.json()).error.code)

            setType(await getType.json())
            setEntity({data:{}})            
        } catch (error) {
            handleError(error)
        }
    }

    const handleOnRendersChange = (props) => {        
        setRenders((prevState) => {
            const newState = [...prevState]
            const index = prevState.findIndex(o => o.id === props.id)
            if (index > -1)
                newState[index] = props
            else
                newState.push(props)

            return newState
        })
    }

    const handleOnClickMainButton = useCallback(() => {
        if ((!entity?.id))
            return handleCreate()
        else
            return handleUpdate()        
    }, [entity, entityData])

    // #region FUNCTIONS
    const getType = useCallback(async (typeId) => {
        // let getFetch = await fetch(`${API_URL_SIMPLECRM.TYPES}${typeId}`, {
        //     method: 'GET',
        //     headers: { 
        //         'Content-Type': 'application/json',
        //         'Authorization': "Bearer "+ accessToken
        //     }            
        // })     
    
        // if (!getFetch.ok)
        //     throw new Error((await getFetch.json()).error.code)
    
        // return await getFetch.json()

        if (!cache.has(typeId)) {
            let getFetch = await fetch(`${API_URL_SIMPLECRM.TYPES}${typeId}`, {
                method: 'GET',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer "+ accessToken
                }            
            })     
            
            if (!getFetch.ok)
                throw new Error((await getFetch.json()).error.code)

            const t = await getFetch.json()

            setCacheData(typeId, t)
            return t
        }
        
        return cache.get(typeId)

    }, [])

    const getEntity = useCallback (async (entityId) => {
        let getFetch = await fetch(API_URL_SIMPLEDATA + entityId, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': "Bearer "+ accessToken
            }            
        })           

        if (!getFetch.ok)
            throw new Error((await getFetch.json()).error.code)

        return await getFetch.json()
    }, [])
    // #endregion
    
    // #region BUTTONS
    
    
    // useEffect(() => {
    //     // if (props.setDialogState) {
    //     //     props.setDialogState(prevState => {
    //     //     let newState = { ...prevState, buttons: Buttons }
    //     //     return newState
    //     // })

    //     if (props.dialogButtons) {
    //         console.log ("---")
    //         props.setDialogButtons(prevState => {
    //         let newState = { ...prevState, buttons: Buttons }
    //         return newState
    //     })
    // }
    // }, [formState])
    // #endregion

    if (props.setDialogButtons)
        props.setDialogButtons((<Buttons formState={formState} entity={entity} handleOnClickMainButton={handleOnClickMainButton} handleReturn={handleReturn}></Buttons>))

    if (
        (type === undefined) ||
        (layout === undefined) ||
        (entity === undefined) 
        // (entityData === undefined)
    ) {        
        return (
            <Box style={{ height: '100%', width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center',}}>
                <SimpleUIDialogListSelector 
                    open={selectorOpenAction}
                    setOpen={setSelectorOpenAction}
                    
                    title="Please select Type"
                    button1Text="Cancel"
                    button2Text="Select"

                    columns={[{ field: 'name', headerName: 'Title', flex: 1 }]}                    

                    fetchRows={handleFetchTypes}

                    onSelect={handleSelectType}
                    onCancel={handleReturn}
                />   

                <CircularProgress  variant="indeterminate" style={{width: '50px', height: '50px'}}/>
            </Box>                  
        ) 
    }    

    if (type === undefined || entity == undefined)
        return (<React.Fragment></React.Fragment>)

    return (
        <React.Fragment>
            {/* <Paper
                sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 'calc(100%)',
                    width: '100%'
                }}
            > */}
                <React.Fragment>
                    <Collapse in={formState.error}>
                        <Alert variant="filled" severity="error" sx={{mb: 2}}
                            action={
                                <IconButton aria-label="close" color="inherit" size="small"
                                    onClick={() => {
                                        setFormState({ ...formState, error: false })
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                        >
                            {formState.errorText}
                        </Alert>
                    </Collapse>
                    <Collapse in={formState.warning}>
                        <Alert variant="filled" severity="warning" sx={{mb: 2}}>
                            {formState.warningText}
                        </Alert>
                    </Collapse>
                </React.Fragment>

                {(!props.setDialogState && !props.onChange) &&
                    <Stack spacing={2} direction="row" justifyContent="end" sx={{ pb: '20px' }}>
                        <Buttons formState={formState} entity={entity} handleOnClickMainButton={handleOnClickMainButton} handleReturn={handleReturn}></Buttons>
                    </Stack>
                }

                {/* <Paper elevation={3} sx={{ p: 2, mb: 2, pb: "50px" }}> */}
                    {layout.build.map((element, elementIndex) => (
                        <Element 
                            element={element}
                            parentType={undefined}

                            entityType={type}
                            entity={entity}

                            state={{get: formState, set: setFormState, onChange: handleOnChange, onRendersChange: handleOnRendersChange}}
                            
                            type={type} 
                            data={entityData}

                            formState={formState} 
                            setFormState={setFormState} 

                            onChange={handleOnChange}
                        >
                        </Element>
                    ))}
                {/* </Paper> */}
            {/* </Paper> */}
        </React.Fragment>
    )
})

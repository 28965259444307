// --------------------------------------------------------- REACT ------
import React, { useState, useEffect, memo } from 'react';
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
import {
    ArrowDownward as ArrowDownwardIcon
} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
import { API_URL_SIMPLECRM } from './../../../../../../components/common';
// import { DataGrid } from '@mui/x-data-grid';
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
import {
    SimpleUIAuthState,
    SimpleUIUseCache
} from './../../../../../../simpleUI';
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
import Render from './../../../render';
import Element from './../../../element';
import { get } from 'use-between';
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
const _temp_type_cache = []
// ----------------------------------------------------------------------
const AccordionComponent = (props) => {
    const { layout, formData, handleAdd, handleEdit, handleDelete } = props;

    const [dataGridRows, setDataGridRows] = useState([]);
    const [expanded, setExpanded] = useState('');

    const {user, accessToken} = SimpleUIAuthState()

    const {cache, setCacheData} = SimpleUIUseCache()

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    useEffect(() => {
        if (!formData) return;

        // let rows = []
        // for (const entity of props.formData) {
        //     // if (entity.data)
        //     //     rows.push({id: entity.id, typeId: entity.typeId, ...entity.data})
        //     // else
        //         rows.push(entity)
        // }

        // const rows = props.formData.map(entity => 
        //     entity.data ? { id: entity.id, typeId: entity.typeId, ...entity.data } : entity
        // );

        // setDataGridRows(rows)

        const rows = formData.map(entity => entity);
        setDataGridRows(rows);
    }, [formData]);
  
    const handleOnChangeRender = (id, formState, typeId, entityData, callback) => {
        props.state.onRendersChange({id: id, valid: formState.valid, dirty: formState.dirty, callback: callback})
    };

    const getType = async (typeId) => {

        console.log (cache.has(typeId) +" "+ typeId)
        if (!cache.has(typeId)) {
            let getFetch = await fetch(`${API_URL_SIMPLECRM.TYPES}${typeId}`, {
                method: 'GET',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer "+ accessToken
                }            
            })     
            
            if (!getFetch.ok)
                throw new Error((await getFetch.json()).error.code)

            const t = await getFetch.json()


            setCacheData(typeId, t)
            return t
        }
        
        return cache.get(typeId)
    }

    return (
        <React.Fragment>
            {dataGridRows.map((entity, index) => (
                <Accordion key={entity.id} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
                    <AccordionSummary
                        expandIcon={<ArrowDownwardIcon />}
                        aria-controls={`${entity.id}-content`}
                        id={entity.id}
                    >
                        {layout.build.summary.content.map((element, layoutIndex) => (
                            <Element
                                key={layoutIndex}
                                element={element}
                                parentType={undefined}
                                entity={entity}
                            />
                        ))}
                    </AccordionSummary>
                    <AccordionDetails>
                        <Render
                            entity={entity}
                            type={getType(entity.typeId)}
                            onChange={(formState, typeId, entityData, callback) => handleOnChangeRender(entity.id, formState, typeId, entityData, callback)}
                        />
                    </AccordionDetails>
                </Accordion>
            ))}
        </React.Fragment>
    );
};

export default memo(AccordionComponent);
import * as React from 'react';
import { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper
} from '@mui/material/';
import Render from './../../../render';
import Element from './../../../element';

const TableComponent = (props) => {
    const { layout, formData, handleAdd, handleEdit, handleDelete } = props;
    const [dataGridRows, setDataGridRows] = useState([]);

    useEffect(() => {
        if (!formData) return;

        const rows = formData.map(entity => entity);
        setDataGridRows(rows);
    }, [formData]);

    const handleOnChangeRender = (id, formState, typeId, entityData, callback) => {
        props.state.onRendersChange({ id, valid: formState.valid, dirty: formState.dirty, callback });
    };

    const renderTableHead = () => (
        <TableHead>
            <TableRow>
                {layout.build.columns.map((column, index) => (
                    column.headerName !== null && <TableCell key={index}>{column.headerName}</TableCell>
                ))}
            </TableRow>
        </TableHead>
    );

    const renderTableBodyWithColumns = () => (
        <TableBody>
            {formData.map((entity, index) => (
                <TableRow key={index}>
                    {layout.build.columns.map((column, colIndex) => (
                        <TableCell key={colIndex} component="th" scope="row">
                            {column.content.map((element, elemIndex) => (
                                <Element
                                    key={elemIndex}
                                    element={element}
                                    parentType="tablecell"
                                    entity={entity}
                                />
                            ))}
                        </TableCell>
                    ))}
                </TableRow>
            ))}
        </TableBody>
    );

    const renderTableBodyWithoutColumns = () => (
        <TableBody>
            {dataGridRows.map((entity, index) => (
                <TableRow key={index}>
                    <TableCell component="th" scope="row">
                        <Render
                            entity={entity}
                            onChange={(renderFormState, typeId, entityData, callback) =>
                                handleOnChangeRender(entity.id, renderFormState, typeId, entityData, callback)
                            }
                        />
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    );

    return (
        <React.Fragment>
            <TableContainer component={Paper}>
                <Table>
                    {layout.build.columns ? renderTableHead() : null}
                    {layout.build.columns ? renderTableBodyWithColumns() : renderTableBodyWithoutColumns()}
                </Table>
            </TableContainer>
        </React.Fragment>
    );
};

export default TableComponent;

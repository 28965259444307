import React, { createContext, useContext, useState } from "react";


const CacheContext = createContext();


export const CacheProvider = ({ children }) => {
    const [cache, setCache] = useState(new Map());
    const setCacheData = (key, value) => {
        setCache(new Map(cache.set(key, value)));
    };

    return (
        <CacheContext.Provider value={{ cache, setCacheData }}>

            {" "}

            {children}{" "}

        </CacheContext.Provider>

    );

};

export const useCache = () => {

  const context = useContext(CacheContext);

  if (!context) {

    throw new Error("useCache must be used within a CacheProvider");

  }

  return context;

};